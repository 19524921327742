<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary">eWork</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title
            title-tag="h2"
            class="
              font-weight-bold
              mb-1
              pt-1
              pb-1
              alert alert-primary
              text-center
            "
          >
            CONNEXION
          </b-card-title>
          <b-card-text class="mb-2">
            Veuillez entrer vos identifiants de connexion afin d'accéder à votre
            compte.
          </b-card-text>
          <div class="alert alert-danger p-1" v-if="errors.message">
            <span class="text-danger text-sm" v-text="errors.message"></span
            ><br />
            <!-- <span v-if="errors.errors"
                >- {{ errors.errors }}
              </span> -->
          </div>

          <b-overlay :show="showLoading" rounded="sm">
            <!-- form -->
            <validation-observer ref="loginValidation">
              <b-form class="auth-login-form mt-2" @submit.prevent>
                <!-- email -->
                <b-form-group label="Email" label-for="login-email">
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="user.email"
                      :state="errors.length > 0 ? false : null"
                      name="login-email"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Mot de passe</label>
                    <b-link :to="{ name: 'forgot-password' }">
                      <small>Mot de passe oublié ?</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="user.password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <!-- <b-form-group>
                  <b-link :to="{ name: 'customer' }">
                    <span>&nbsp;Je suis un client</span>
                  </b-link>
                </b-form-group> -->

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  @click.prevent="validationForm"
                >
                  Valider
                </b-button>
              </b-form>
            </validation-observer>
          </b-overlay>

          <!-- <b-card-text class="text-center mt-2">
            <span>Vous n'avez pas de compte ? </span>
            <b-link :to="{ name: 'ky-register' }">
              <span>&nbsp;Créez-en un ici</span>
            </b-link>
          </b-card-text> -->

          <b-card-text class="text-center mt-2">
            <span>Vous êtes un client ? </span>
            <b-link :to="{ name: 'customer-login' }">
              
              <span>&nbsp;Connectez-vous ici</span>
            </b-link>
          </b-card-text>

          <!-- <b-button type="submit" variant="primary" block to="customer-login">
            Espace client
          </b-button> -->
        </b-col>
      </b-col>

      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import kyInput from "@/@core/layouts/components/ky-input.vue";
import VuexyLogo from "@/@core/layouts/components/logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    kyInput,
    ToastificationContent,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      email: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      user: {},
      errors: {},
      showLoading: false,
      errorMessage: "",
      error: "",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    async validationForm() {
      const valid = await this.$refs.loginValidation.validate();
      if (!valid) return;
      this.showLoading = true;
      this.login(this.$formData(this.user))
        .then((r) => {
          this.showLoading = false;
          this.$router.replace("/login-validate");
          localStorage.setItem("email", this.user.email);
        })
        .catch((error) => {
          this.showLoading = false;
          this.errorMessage = error;
          this.errors = error.response;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
      //this.$http.post("enterprise",this.enterprise)
    },

    ...mapActions("auth", ["login"]),
  },

  mounted() {},
};
history.pushState(null, document.title, location.href);
window.addEventListener("popstate", function (event) {
  history.pushState(null, document.title, location.href);
});
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
